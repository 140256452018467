<template>
  <div>
    <!-- index for /partners -->
    <!-- Section containing the add an upload buttons per page e.g. MySources, MyCollections -->
    <section class="bg-grey-4" v-if="isAdmin">
      <div class="row q-mx-auto justify-st art items-center">
        <div class="col q-pa-md">
          <q-btn icon="add" label="Add Partner" @click="addPartner" />
        </div>
      </div>
    </section>

    <!-- CODE FOR MODAL -->
    <q-dialog
      v-model="showDialog"
      ref="addPartner"
      persisent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card style="max-width: 640px; min-width: 60vw; min-height: 70vh">
        <q-bar :class="`bg-${getColor} text-white`">
          <span style="text-transform: capitalize">{{ mode }} Partner</span>
          <q-space />
          <q-btn @click="showDialog = false" dense round flat icon="close" />
        </q-bar>

        <q-tabs
          dense
          v-model="tab"
          class="text-grey bg-grey-4"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="main" label="Main" />
          <q-tab name="europeana" label="Europeana" />
        </q-tabs>

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="main">
            <h4>Settings</h4>
            <q-select
              filled
              v-model="partner.member"
              use-input
              input-debounce="0"
              :options="members"
              @filter="getMembers"
              label="Email of member whom is partner-admin"
              class="q-mb-md"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">No results</q-item-section>
                </q-item>
              </template>
            </q-select>

            <q-input
              filled
              v-model="partner.name"
              label="Name of partner"
              class="q-mb-md"
            />
            <q-input
              filled
              type="textarea"
              v-model="partner.introduction"
              label="Short introduction text"
              min-height="5rem"
            />
            <p>&nbsp;</p>
            <!-- Upload logo -->
            <div
              v-if="partner.logo && partner.logo.url"
              style="border: 1px solid #bebebe"
              class="q-pa-md"
            >
              <q-btn
                @click="partner.logo = { uuid: '', url: '' }"
                round
                icon="fa fa-trash-alt"
                title="Delete logo"
                class="float-right"
                color="hardroze"
              />
              <img
                :src="partner.logo.url"
                style="max-height: 140px; width: auto"
              />
            </div>

            <q-uploader
              v-show="false"
              ref="uploader_logo"
              url="/api/upload"
              :headers="getHeaders('logo')"
              :multiple="false"
              accept=".jpg, image/*"
              label="Add logo"
              @uploaded="processUpload"
              auto-upload
            />

            <q-btn
              size="md"
              no-caps
              @click="$refs.uploader_logo.pickFiles()"
              label="Upload logo"
              icon="fa fa-upload"
              class="q-my-sm"
              color="hardroze"
            />

            <p class="text-body2">
              The logo is displayed on the partners index and partner page. It
              should be max 140px heigh and max. 300px wide.
            </p>

            <!-- Upload icon -->

            <div
              v-if="partner.icon && partner.icon.url"
              style="border: 1px solid #bebebe"
              class="q-pa-md"
            >
              <q-btn
                @click="partner.icon.url = { uuid: '', url: '' }"
                round
                size="md"
                icon="fa fa-trash-alt"
                title="Delete icon"
                class="float-right"
                color="hardroze"
              />
              <img
                :src="partner.icon.url"
                style="
                  max-width: 48px;
                  max-height: 48px;
                  background-color: #bebebe;
                "
              />
            </div>
            <q-uploader
              color="getColor"
              v-show="false"
              ref="uploadIcon"
              url="/api/upload"
              :headers="getHeaders('icon')"
              :multiple="false"
              accept=".jpg, image/*"
              label="Add icon"
              @uploaded="processUpload"
              auto-upload
            />

            <q-btn
              size="md"
              no-caps
              @click="$refs.uploadIcon.pickFiles()"
              label="Upload white icon"
              icon="fa fa-upload"
              class="q-my-sm"
              color="hardroze"
            />
            <p class="text-body2">
              The icon is displayed on the right hand side of Cards. It should
              be white with a transparant background and max 24x24 pixels.
            </p>
          </q-tab-panel>
          <q-tab-panel name="europeana">
            <h4>Europeana connection</h4>
            <p>
              Connect this partner to known (data)providers in Europeana.<br />
            </p>

            <br />

            <h5>Dataproviders</h5>
            <q-select
              v-model="partner.dataproviders"
              multiple
              :options="filteredDataproviders"
              use-chips
              use-input
              fill-input
              stack-label
              input-debounce="200"
              @filter="filterDataProvider"
              :label="`Search in ${dataproviders.length} dataproviders`"
              hint="Associated Europeana Dataproviders"
              clearable
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>

            <h5 class="q-mt-xl">Providers</h5>

            <q-select
              v-model="partner.providers"
              multiple
              :options="filteredProviders"
              use-chips
              use-input
              fill-input
              stack-label
              input-debounce="200"
              :label="`Search in ${providers.length} providers`"
              @filter="filterProvider"
              hint="Associated Europeana Providers"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-tab-panel>
        </q-tab-panels>

        <q-card-actions align="center" class="q-mb-md">
          <q-btn
            no-caps
            :disabled="false"
            @click="showDialog = false"
            label="Cancel"
          />
          <q-btn
            no-caps
            color="green"
            :disabled="isEmpty"
            @click="savePartner"
            label="Save"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <span :key="p.uuid" v-for="p in partners" :id="p.uuid">
            <Card
              :url="`/partners/${p.slug}`"
              :partner="p"
              :item="p"
              :title="p.name"
              :btn-delete="isAdmin"
              :uuid="p.uuid"
              :image-url="p.logo.url"
              type="Partner"
              :csstype="p.is_visible ? 'partner published' : 'partner'"
              class="animated slideInUp fast"
              @deleteCard="deleteCard"
              @showEdit="editCard"
              :btn-edit-modal="isAdmin"
              :btn-publish="!p.is_visible && isAdmin"
              :btn-un-publish="p.is_visible && isAdmin"
              @publish="publishPartner"
              @unpublish="unpublishPartner"
            >
              <!-- <small>{{ p.uuid }}</small> -->
              {{ p.introduction }}
              <br />
            </Card>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import _ from "lodash";
import Vue from "vue";
import { API } from "@/tic";
import Card from "../tags/Card";
import { mapGetters } from "vuex";
import getColor from "../mixins/getColor.mixin.js";

let partner = {
  name: "",
  introduction: "",
  member: "",
  icon: { uuid: "", url: "" },
  logo: { uuid: "", url: "" },
};

export default {
  name: "PartnersIndex",
  mixins: [getColor],
  components: { Card },
  mounted() {
    API.get("/partners").then((r) => {
      if (this.isAdmin) {
        this.partners = r.data.data;

        API.get("/system/eu-providers").then((r) => {
          this.providers = r.data.data.providers;
          this.dataproviders = r.data.data.dataproviders;
        });
      } else {
        this.partners = r.data.data.filter((e) => {
          return e.is_visible;
        });
      }
    });
  },

  computed: {
    ...mapGetters("user", ["isAdmin"]),
    isEmpty: function () {
      //console.log("XXX: ", Object.values(this.partner))
      //console.log("ZZZ: ", _.isEmpty(Object.values(this.partner)))
      return _.isEmpty(Object.values(this.partner).filter(Boolean));
    },
  },

  methods: {
    filterDataProvider(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredDataproviders = this.dataproviders.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    filterProvider(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredProviders = this.providers.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    getMembers(search, update, abort) {
      // call the API for a list of matching Member.email records

      if (search.length < 2) {
        abort();
        return;
      }

      API.post("/partners/get-members", { search: search }).then((r) => {
        update(() => {
          this.members = r.data.data;
          this.member = "";
        });
      });
    },

    publishPartner(p) {
      console.log("publish: ", p);
      API.post("/partners/toggle/visibility", {
        partner: p.uuid,
        session: this.$store.getters["user/session"],
      }).then((r) => {
        console.log("reply: ", r);
        // reload partners
        API.get("/partners").then((r) => (this.partners = r.data.data));
      });
    },

    unpublishPartner(p) {
      console.log("unpublish: ", p);
      API.post("/partners/toggle/visibility", {
        partner: p.uuid,
        session: this.$store.getters["user/session"],
      })
        .then((r) => {
          console.log("reply: ", r);
          // reload partners
          API.get("/partners").then((r) => (this.partners = r.data.data));
        })
        .catch((err) => {
          console.log("=================");
          console.log("e: ", err);
          console.log("m: ", err.message);
          console.log("t: ", err.type);
          console.log("typeof: ", typeof err);
          console.log("xxx: ", err.response);
          //window.eventBus.$emit('alert', err);
        });

      return true;
    },

    addPartner() {
      this.mode = "add";
      this.partner = partner;
      Vue.set(this.partner, partner);
      this.showDialog = !this.showDialog;
    },

    editCard(card) {
      console.log("EDIT CARD", card);
      this.mode = "edit";
      this.partner = card;
      if (
        this.partner.providers === undefined ||
        this.partner.providers === ""
      ) {
        this.partner.providers = [];
      }
      if (
        this.partner.dataproviders === undefined ||
        this.partner.dataproviders === ""
      ) {
        this.partner.dataproviders = [];
      }

      this.showDialog = true;
    },

    deleteCard(i, uuid) {
      API.post("/partners/delete", { uuid: uuid }).then(() => {
        API.get("/partners").then((r) => (this.partners = r.data.data));
      });
    },

    getHeaders(item) {
      return [
        {
          name: "X-Session",
          value: this.$store.getters["user/session"],
        },
        { name: "X-Process", value: "createPartnerIcon" },
        { name: "item", value: item },
      ];
    },

    processUpload(u) {
      // get uuid+url from upload reply and add them to this partner
      let reply = JSON.parse(u.xhr.response);
      this.partner[reply.item].url = reply.url;
      this.partner[reply.item].uuid = reply.uuid;
      Vue.set(this.partner, reply.item, {
        url: reply.url,
        uuid: reply.uuid,
      });
    },

    // processUploadIcon(resp) {
    //   let reply = JSON.parse(resp.xhr.response);
    //   console.log("r: ", reply);
    //   this.partner[reply.item].url = reply.url;
    //   this.partner[reply.item].uuid = reply.uuid;
    //   console.log("icon uploaded");
    // },

    savePartner() {
      console.log("* SAVE PARTNER");
      // this.$refs.addPartner.hide()
      API.post(`/partners/${this.mode}/`, this.partner).then(() => {
        this.showDialog = false;
        API.get("/partners").then((r) => (this.partners = r.data.data));
        this.$store.dispatch("sendFeedback", {
          msg: `Partner ${this.mode}ed succesfully`,
          status: "ok",
        });
      });
    },
  },

  data() {
    return {
      tab: "main",
      mode: "add",
      member: null,
      members: [],
      partners: [],
      showDialog: false,

      filteredDataproviders: [],
      filteredProviders: [],
      dataproviders: [],
      providers: [],

      headers: [
        // these are needed in the API to identify the user
        {
          name: "X-Session",
          value: this.$store.getters["user/session"],
        },
        { name: "X-Process", value: "createPartnerIcon" },
      ],

      partner: partner,
    };
  },
};
</script>
<style>
.image-preview {
  max-width: 50%;
  background-color: #ccc;
  padding: 2em;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
